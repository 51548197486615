import React from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import localStorageService from './localStorageService';
import {axiosInstance} from './api/axiosInstance';
import './App.css';
import {getApiEndpoint} from './api/apiHelper';
import PostsSlider from './components/PostsSlider';

const allCapsAlpha = [..."ABCDEFGHIJKLMNOPQRSTUVWXYZ"];
const allNumbers = [..."0123456789"];
const base = [...allCapsAlpha, ...allNumbers];

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // timeout: 1000 * 60 * 20, // prod
      timeout: 1000 * 60 * 2, // dev
      deviceId: localStorageService.getDeviceId() ?? null,
      authorized: false,
      lang: localStorageService.getLanguage() ?? 'cs'
    }
  }

  componentDidMount() {
    let deviceId = localStorageService.getDeviceId();
    let accessToken = localStorageService.getAccessToken();

    if (deviceId === null) {
      deviceId = this.generator(base, 6);
      localStorageService.setDeviceId(deviceId);
      this.setState({deviceId: deviceId});
      this.authorizeApp(deviceId);
    } else if (accessToken === null) {
      this.authorizeApp(deviceId);
    }

    if(deviceId && accessToken) {
      this.setState({authorized: true});
    }
  }

  generator = (base, len) => {
    return [...Array(len)]
        .map(i => base[Math.random()*base.length|0])
        .join('');
  };

  authorizeApp(deviceId) {
    axiosInstance.post(getApiEndpoint('login'), {device_id: deviceId})
        .then((response) => {
          if(response.status === 200) {
            let accessToken = response.data.data.access_token;
            localStorageService.setToken(accessToken);
            this.setState({authorized: true});
          }
        }).catch((error) => {
      console.log(error);
    })
  }

  render () {
    let {authorized, deviceId} = this.state;

    return (
        <div className="App">
          {(authorized) &&
              <div>
                <BrowserRouter basename={`/`}>
                  {(authorized) &&
                      <Routes>
                        {/*Languages*/}
                        <Route path="/" exact>
                          {/*<Languages changeLanguage={this.changeLanguage.bind(this)} />*/}
                        </Route>

                        <Route path="/:lang" element={<PostsSlider />} />
                      </Routes>
                  }
                </BrowserRouter>
                {/*<div className={"deviceIdBox"}>{deviceId}</div>*/}
              </div>
          }
          {!authorized &&
              <div className={"unauthorized"}>
                <div className={"unauthorized__content"}>
                  <p>Tato obrazovka není autorizovaná. Zkuste to později.</p>
                  <p>Kód pro autorizaci</p>
                  <p className={"code"}>{deviceId}</p>
                  <button type={"button"} onClick={this.authorizeApp.bind(this, localStorageService.getDeviceId())}>Zkusit znovu</button>
                </div>
              </div>
          }
        </div>
    );
  }
}

export default App;
