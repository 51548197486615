/**
 * Created by MARK 31 s.r.o.
 * User: Pavel Bocek
 * Date: 21.07.22
 */
import React from 'react';
import ReactPlayer from 'react-player'
import {axiosInstance} from '../api/axiosInstance';
import {getApiEndpoint} from '../api/apiHelper';
import {useParams} from 'react-router';
import {Swiper, SwiperSlide} from "swiper/react";
import { Document, Page, pdfjs } from 'react-pdf';
import "swiper/css";
import InstagramFeed from 'react-ig-feed';
import 'react-ig-feed/dist/index.css';
import FacebookFeed from './FacebookFeed';
import NewsPost from './NewsPost';
import localStorageService from '../localStorageService';

class PostsSlider extends React.Component {
    constructor(props) {
        super(props);
        pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

        this.state = {
            interval: 10000,
            currentSlide: 0,
            posts: [],
            posts_orig: [],
            swiper: null,
            key: Math.random(),
            isLoaded: false,
            timer: false,
            firstChange: true,
            timeToUpdate: false,
            updateTimer: false,
            deviceName: ''
        };
    }

    componentDidMount() {
        let {lang} = this.props.params;

        axiosInstance.get(getApiEndpoint('getPosts', {lang: lang}))
            .then((response) => {
                if(response.status === 200) {
                    let posts = response.data.data.eboard_posts;
                    let deviceName = response.data.data.eboard_name + ' / '+ localStorageService.getDeviceId();
                    if(posts.length > 0) {
                        posts.forEach((post, index) => {
                            posts[index] = {
                                ...post,
                                playing: false
                            }
                        })
                    }

                    let timerUpdate = setTimeout(()=> {
                        clearTimeout(this.state.updateTimer);
                        this.setState({timeToUpdate: true});
                    }, 1000 * 60 * 1);
                    this.setState({posts: posts, posts_orig: posts, isLoaded: true, timeToUpdate: false, updateTimer: timerUpdate, deviceName: deviceName});
                }
            }).catch((error) => {
            console.log(error);
            throw new Error('Problem with API');
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevState.key !== this.state.key) {
            if(this.state.timeToUpdate) {
                let {lang} = this.props.params;

                axiosInstance.get(getApiEndpoint('getPosts', {lang: lang}))
                    .then((response) => {
                        if(response.status === 200) {
                            let posts = response.data.data.eboard_posts;
                            let deviceName = response.data.data.eboard_name + ' / '+ localStorageService.getDeviceId();
                            if(posts.length > 0) {
                                posts.forEach((post, index) => {
                                    posts[index] = {
                                        ...post,
                                        playing: false
                                    }
                                })
                            }

                            let timerUpdate = setTimeout(()=> {
                                this.setState({timeToUpdate: true});
                            }, 1000 * 60 * 1);
                            this.setState({posts: posts, posts_orig: posts, isLoaded: true, timeToUpdate: false, updateTimer: timerUpdate, deviceName: deviceName});
                        }
                    }).catch((error) => {
                    console.log(error);
                    throw new Error('Problem with API');
                })
            }else{
                this.setState({posts: this.state.posts_orig, isLoaded: true});
            }
        }

        if(this.state.timeToUpdate && (this.state.posts.length === 1 && this.state.posts[0].content_type !== 'video') && prevState.key === this.state.key) {
            this.setState({key: Math.random(), isLoaded: false});
        }
    }

    videoEnded = () => {
        if(this.state.posts.length === 1) {
            this.setState({key: Math.random(), isLoaded: false});
        } else {
            let swiper = this.state.swiper;
            let currentSlide = swiper.realIndex;
            let posts = this.state.posts;
            let post = posts[currentSlide];

            post.playing = false;
            posts[currentSlide] = post;

            // this.setState({posts: posts});

            if(posts.length === swiper.realIndex + 1) {
                if (this.state.timeToUpdate) {
                    this.setState({key: Math.random(), isLoaded: false});
                } else {
                    swiper.slideTo(0, 500);
                }
            }else{
                swiper.slideNext(500);
            }
        }

    }

    handleError = () => {
        this.videoEnded();
    }

    handleLoopError = (error, data) => {
        this.setState({key: Math.random(), isLoaded: false});
    }

    updateSwiperCurrentSlide = (swiper) => {
        clearTimeout(this.state.timer);

        if(swiper.initialized) {
            let index = swiper.realIndex;
            let posts = this.state.posts;
            let currentPost = posts[index];

            let nextSlideIndex = index + 1 === this.state.posts.length ? 0 : index + 1;

            if(currentPost.content_type === 'video') {
                let post = posts[index];
                post.playing = true;
                posts[index] = post;
                this.setState({posts: posts});
            }else{
                if(index + 1 === posts.length) {
                    let timer = setTimeout(()=> {
                        this.setState({key: Math.random(), isLoaded: false});
                    }, currentPost.duration);
                    this.setState({timer: timer});
                }else{
                    let timer = setTimeout(()=> {
                        swiper.slideTo(nextSlideIndex, 500);
                    }, currentPost.duration);
                    this.setState({timer: timer, duration: currentPost.duration, swiper: swiper});
                }
            }
        }else{
            let index = swiper.realIndex;
            let posts = this.state.posts;
            let currentPost = posts[index];

            if(currentPost.content_type === 'video') {
                let post = posts[index];
                post.playing = true;
                posts[index] = post;
                this.setState({posts: posts});
            }else{
                if(index + 1 === posts.length) {
                    let timer = setTimeout(()=> {
                        this.setState({key: Math.random(), isLoaded: false});
                    }, currentPost.duration);
                    this.setState({timer: timer});
                }else{

                }
                let timer = setTimeout(()=> {
                    swiper.slideNext(500);
                }, currentPost.duration);
                this.setState({timer: timer, duration: currentPost.duration, swiper: swiper});
            }
        }

        this.setState({swiper: swiper})
    }

    onDocumentLoadSuccess() {
        console.log('load success');
    }

    render() {
        let {isLoaded, posts, deviceName} = this.state;

        if(isLoaded) {
            if(posts.length > 1) {
                return (
                    <div className={'screensaver-wrapper'} key={this.state.key}>
                        <Swiper
                            slidesPerView={1}
                            spaceBetween={0}
                            pagination={false}
                            onSnapIndexChange={this.updateSwiperCurrentSlide}
                            allowTouchMove={false}
                        >
                            {posts.map((post,index) => {
                                let postId = 'post-'+index;
                                if(post.content_type === 'video') {
                                    return <SwiperSlide key={postId}>
                                        <ReactPlayer slide={this.state.currentSlide} url={post.file_url} onEnded={this.videoEnded} onError={this.handleError} loop={false} playing={post.playing} muted={true} width={'100%'} height={'100%'} />
                                    </SwiperSlide>
                                }else if(post.content_type === 'image'){
                                    return <SwiperSlide key={postId}>
                                        <img src={post.file_url} alt={''} />
                                    </SwiperSlide>
                                }else if(post.content_type === 'pdf'){
                                    return <SwiperSlide key={postId}>
                                        <Document file={{url: post.pdf_url, httpHeaders:{
                                            'Authorization': 'Basic ' + btoa('devel:VrEQQ4Br')
                                            }}} onLoadSuccess={this.onDocumentLoadSuccess}>
                                            <Page pageNumber={1} />
                                        </Document>
                                    </SwiperSlide>
                                }else if(post.content_type === 'ig_feed'){
                                    return <SwiperSlide key={postId}>
                                        <div className={'ig-feed'}>
                                            <InstagramFeed token={post.ig_token} counter={15} />
                                        </div>
                                    </SwiperSlide>
                                }else if(post.content_type === 'fb_feed'){
                                    return <SwiperSlide key={postId}>
                                        <FacebookFeed data={post.fb_posts} />
                                    </SwiperSlide>
                                }else if(post.content_type === 'post'){
                                    return <SwiperSlide key={postId}>
                                        <NewsPost data={post.post_info} />
                                    </SwiperSlide>
                                }else{
                                    return <></>
                                }
                            })}
                        </Swiper>
                        <div className={'deviceIdBox'}>{deviceName}</div>
                    </div>
                )
            }else{
                return (
                    <div>
                        {posts.map((post,index) => {
                            let postId = 'post-'+index;
                            if(post.content_type === 'video') {
                                return  <ReactPlayer key={postId} slide={this.state.currentSlide} url={post.file_url} onEnded={this.videoEnded} onError={this.handleError} loop={false} playing={true} muted={true} width={'100%'} height={'100%'} />
                            }else if(post.content_type === 'image'){
                                return <div className={'image-wrapper'} key={postId}>
                                    <img src={post.file_url} alt={''} />
                                </div>
                            }else if(post.content_type === 'pdf'){
                                return <Document key={postId} file={{url: post.pdf_url}} onLoadSuccess={this.onDocumentLoadSuccess}>
                                    <Page pageNumber={1} />
                                </Document>
                            }else if(post.content_type === 'ig_feed'){
                                return <div key={postId} className={'ig-feed'}>
                                    <InstagramFeed token={post.ig_token} counter={15} />
                                </div>
                            }else if(post.content_type === 'fb_feed'){
                                return <FacebookFeed key={postId} data={post.fb_posts} />
                            }else if(post.content_type === 'post' && post.post_info){
                                return <NewsPost key={postId} data={post.post_info} />
                            }else{
                                return <></>
                            }
                        })}
                        <div className={'deviceIdBox'}>{deviceName}</div>
                    </div>
                )
            }

        }else{
            return (
                <p>...</p>
            )
        }

    }
}

export default (props) => (
    <PostsSlider
        {...props}
        params={useParams()}
    />
);
